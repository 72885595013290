<template>
  <div style="padding-left: 1.75rem">
    <clasic-modal v-model="historyModalOpen">
      <history-modal
        :options="historyModalData"
        @Close="closeHistoryModal"
        @openVersion="(...x) => openPaapHistory(...x)"
      />
    </clasic-modal>
    <div class="col2_head">
      <router-link :to="'/'" class="breadcrumb_home">Acasă /</router-link>
      {{ $route.name }}
      <div class="subHeadTitle">{{ this.$route.name }}</div>
      <div class="right" style="margin-top: 1.2rem">
        <div v-if="showPanel" @click="closePanel()" class="goBack">
          <img src="../assets/media/vectors/arrow_back.svg" alt="" />
        </div>
      </div>
    </div>
    <Panel
      v-if="showPanel"
      :options="panelData"
      @closePanel="closePanel"
      @viewPdf="viewPdf"
    />
    <template v-else>
      <template v-if="PERMISIONS.list">
        <table-parent
          :startup="allowTableSync"
          :reloadCount="reloadTable"
          :tableHead="tableHead"
          :prepareFn="prepareData"
          :apiModule="tableModule"
          :apiModuleName="'PAAP'"
          @Btn7="viewRecord"
          @Btn3="deleteRecord"
          @Btn5="openAddress"
          @Btn13="viewPaapVersions"
          @Btn14="viewPdf"
          @Btn42="requestApporove"
          @totalRecords="(x) => (totalRecords = x)"
        />
      </template>
      <template v-else>
        <h3 style="display: flex; flex-direction: column; align-items: center">
          <span style="font-size: 4rem">
            <i class="fas fa-exclamation-triangle"></i>
          </span>
          Acces interzis
        </h3>
      </template>
    </template>
  </div>
</template>

<script>
import PermisionsMixin from "@/mixins/paapPermMixin.js";
import updateRoleStatus from "../mixins/updateUserRoleStatus.js";
import { PAAP } from "../api.js";
import { mapMutations } from "vuex";

import DialogModal from "@/components/DialogModal";
import PaapHistoryModal from "@/components/PaapHistoryModal";

import TableParent from "@/components/TableParent";
import Panel from "@/components/PaapPanel";

export default {
  name: "Paap",
  $Title() {
    return `ConectX - ${this.$route.name}`;
  },
  components: {
    TableParent,
    Panel,
    "history-modal": PaapHistoryModal,
  },
  mixins: [PermisionsMixin, updateRoleStatus],
  inject: ["ReferenceReject"],
  data() {
    return {
      historyModalOpen: false,
      historyModalData: null,
      tableHead: [
        {
          title: "Instituție",
          queryKey: "institution.name",
          bottomHead: {
            quikFiltre: true,
          },
        },
        {
          title: "Denumire",
          sort: true,
          queryKey: "name",
          bottomHead: {
            quikFiltre: true,
          },
        },
        {
          title: "Anul PAAP",
          queryKey: "paapyear",
          bottomHead: {
            quikFiltre: "date",
            dateType: "year",
            dateFormat: "YYYY",
            exactSearch: true,
          },
        },
        {
          title: "Suma totală",
          queryKey: "totalValueWithoutTva",
          bottomHead: {
            quikFiltre: "number",
            exactSearch: true,
          },
        },
        {
          title: "Versiune",
          queryKey: "approveVersion",
          bottomHead: {
            quikFiltre: "list",
            options: [
              { value: "initial", label: "Inițială" },
              { value: "updated", label: "Actualizată" },
            ],
          },
        },
        {
          title: "Data creării",
          queryKey: "createdAt",
          bottomHead: {
            quikFiltre: "date",
            range: true,
          },
        },
        {
          spacer: true,
          minimize: 1,
          fixed: {
            right: true,
          },
          queryKey: "paapStatus",
          bottomHead: {
            quikFiltre: "paap",
          },
        },
        {
          spacer: true,
          minimize: 1,
          fixed: {
            right: true,
          },
          bottomHead: {
            quikFiltre: "clear",
          },
        },
      ],
      tableModule: PAAP.get,
      panelData: false,
      totalRecords: 0,
      reloadTable: 0,
    };
  },
  methods: {
    ...mapMutations(["addWindow", "setReferenceIdForPreview"]),
    reload() {
      this.reloadTable++;
    },
    getComm(data) {
      const commMap = (status) =>
        ({
          rejected: {
            title: "Respinge PAAP",
            labels: ["Respins", "respingerii", "Motivul respingerii"],
            button: { value: "Respingere", type: 3 },
          },
          approved: {
            title: "Aprobă PAAP",
            labels: ["Aprobat", "aprobării", "Motivul aprobării"],
            button: { value: "Aprobare", type: 2 },
          },
        }[status] || {
          title: "-",
          labels: [],
          button: {},
        });

      this.addWindow({
        modal: 11,
        rejectData: {
          referenceData: data,
        },
        ...commMap(data?.status),
      });
    },
    viewPublik(item) {
      if (this.isString(item?.token)) {
        window.open(`/public/paap/${item.token}`);
      }
    },
    viewPdf(data) {
      if (!data?.id) {
        this.$toastr.e("PAAP Id not found.");
        return;
      }

      this.setReferenceIdForPreview({
        data: {
          id: data.id,
        },
        origin: "PAAP_GET_PDF",
      });
    },

    requestApporove(data) {
      if (!Number.isInteger(data.id)) return;

      if (data.paapyear < new Date().getFullYear()) {
        this.$toastr.w(
          "PAAP-ul nu mai poate fi modificat, nu se încadrează în anul curent."
        );
        return;
      }

      this.setLoad(true);

      PAAP.requestApporove(data.id)
        .then((res) => {
          if (this.checkHttpStatusCode(res?.meta?.HttpStatusCode)) {
            window.open(res.data.result.url, "_blank");
            this.setLoad();
          } else {
            error(res.response);
          }
          this.setLoad(false);
          this.reload();
        })
        .catch((e) => {
          this.$toastr.e(e || "A apărut o eroare.");
          this.reload();
        });
    },
    changeStatus(id, status, callback, confirm) {
      if (!confirm) {
        this.getComm({ id, status, callback });
        return;
      }
      if (!Number.isInteger(id) || !this.isString(status)) return;
      this.setLoad(true);
      PAAP.apporove(id, {
        paapStatus: status,
      })
        .then((res) => {
          this.setLoad(false);
          if (typeof callback == "function") callback(res);
        })
        .catch((e) => {
          this.$toastr.e(e || "A apărut o eroare.");
          this.setLoad(false);
          if (this.generateReferenceData) this.cancelRf();
          else this.reload();
        });
    },
    /*signReference(data, pos) {
      if (!Number.isInteger(data?.id)) return;

      this.changeStatus(data.id, pos ? "approved" : "rejected", (res) => {
        if (this.checkHttpStatusCode(res?.meta?.HttpStatusCode)) {
          this.$toastr.s(
            `PAAP-ul a fost ${pos ? "aprobat" : "respins"} cu succes`,
            "Succes!"
          );
        } else {
          this.$toastr.e(
            `${pos ? "Aprobarea" : "Respingerea"} PAAP-ului a decurs cu errori.`
          );
        }
        this.reload();
      });
    },*/

    prepareData(row) {
      const approveVersion = {
        1: "Inițială",
        2: "Actualizată",
        3: "În execuție",
      };
      const getStatusIcon = (s) =>
        ({
          asteptare_aprobare: {
            border: "left",
            icon: "fas fa-clock",
            css: "padding: 0.5rem;color: #f2da07;font-size: 2rem;",
            tooltip: "Așteptare aprobare",
          },
          draft: {
            border: "left",
            icon: "far fa-clock",
            css: "padding: 0.5rem;color: #f2da07;font-size: 2rem;",
            tooltip: "Draft",
          },
          rejected: {
            border: "left",
            icon: "fas fa-times-circle",
            css: "padding: 0.5rem;color: #ec4a4a;font-size: 2rem;",
            tooltip: "Respins",
          },
          approved: {
            border: "left",
            icon: "fas fa-clipboard-check",
            css: "padding: 0.5rem;color: #569a36;font-size: 2rem;",
            tooltip: "Aprobat",
          },
        }[s] || s);

      const preparation = [
        row.institution?.name || "-",
        row.name || "-",
        row.paapyear || "-",
        this.toPriceFormat(row.totalValueWithoutTva),
        approveVersion[row.approveVersion],
        this.toDateAndTime(row.createdAt),
        getStatusIcon(row.paapStatus) || "-",
      ];

      const uuid = row.createdByUser;
      const x = this.PERMISIONS;
      const prepareBtn = [];
      const statuses = Object.keys(getStatusIcon);

      prepareBtn.push(14);

      if (x.edit === true && ["draft", "rejected"].includes(row.paapStatus)) {
        prepareBtn.push([
          42,
          "Trimite spre aprobare",
          ["fas fa-signal-stream", "font-size:2.2rem;color:#39f;"],
        ]);
      }

      prepareBtn.push(7);

      if (x.edit === true && row.paapStatus == "asteptare_aprobare") {
        prepareBtn.push([
          5,
          "Deschide lucrarea",
          ["fas fa-book", "font-size:2.2rem;color:#39f;"],
        ]);
      }

      prepareBtn.push(13);

      if (prepareBtn.length) {
        preparation.push([prepareBtn]);
      }

      return preparation;
    },
    openAddress(item) {
      window.open(item.conectxAddressUrl, "_blank");
    },
    closePanel() {
      this.panelData = false;
      const currUrl = this.$route.path;
      const backUrl = currUrl.substring(0, currUrl.lastIndexOf("/"));
      this.$router.push(backUrl);
    },
    openPanel(options) {
      if (["object", "function"].includes(typeof options)) {
        this.simulateLoad(
          () =>
            (this.panelData =
              typeof options == "function" ? options() : options)
        );
      }
    },

    openVersion(
      paapId,
      centralizationId,
      versionNumber,
      versions,
      versionsQty
    ) {
      this.closeHistoryModal();
      const clearUrl = () => {
        this.$toastr.w("Versiunea nu a fost găsită.");
        this.setLoad();
        this.safeBackRoute();
      };

      paapId = parseInt(paapId);
      centralizationId = parseInt(centralizationId);
      versionNumber = parseFloat(versionNumber);

      if (
        !paapId ||
        !this.isNumber(versionNumber) ||
        !Array.isArray(versions)
      ) {
        clearUrl();
        return;
      }

      versionsQty = versionsQty ?? versions.length;

      versions = versions
        .map((e) => {
          let label = "";

          const num = versionNumber;
          label = `V${num?.toFixed(2)}`;

          if (num + 1 >= versionsQty) {
            label += " — Curentă";
          }

          return {
            ...(e?.data?.oldPaap ?? e?.data),
            versionNumber: e.versionNumber,
            versionCreatedAt: e.createdAt,
            label,
          };
        })
        .filter((e) => this.isObject(e));

      if (!versions.length) {
        clearUrl();
        return;
      }
      const versionId = versionNumber;

      const combinedVersions = (() => {
        let prepare = [];

        const causeVersions = {};
        versions.forEach((value, index) => {
          if (value.updateCause) {
            causeVersions[index] = value;
          } else {
            prepare.push(value);
          }
        });

        prepare = this.combineObjects(prepare);

        for (let cause of Object.entries(causeVersions)) {
          prepare.splice(cause[0], 0, cause[1]);
        }

        return prepare;
      })();

      let preparedVersion = combinedVersions.find(
        (e) => e.versionNumber === versionId
      );

      preparedVersion = preparedVersion.oldPaap ?? preparedVersion;

      preparedVersion.needReferate = preparedVersion.needReferate.filter((el) =>
        el.paapCentralization.find((el) => el.id == centralizationId)
      );

      if (preparedVersion) {
        this.openPanel(() => {
          this.safePushRouter(
            `/${this.$route.params.navigation}/paap/${paapId}/centralizare/${centralizationId}/history/${versionNumber}`
          );

          return {
            action: "viewVersion",
            data: preparedVersion,
          };
        });
      } else {
        this.setLoad();
        this.closePanel();
      }
    },

    createRecord() {
      this.openPanel({
        action: "create",
        totalRecords: this.totalRecords,
      });
    },
    viewRecord(data) {
      if (!Number.isInteger(data?.id)) return;

      this.syncUrlForPaap(data);
    },
    deleteRecord(data) {
      if (!Number.isInteger(data?.id)) return;

      this.$modal.show(
        DialogModal,
        {
          target: "PAAPDelete",
          title: "Ștergere PAAP",
          content:
            '<span style="color:#f44336;display: block;margin-top: 2rem;"><i class="fas fa-exclamation-circle" style="font-size: 6rem;"></i> <p style="font-weight: bold;font-size: 2.2rem;">Ești sigur că vrei să ștergi PAAP-ul selectat?</p></span>',
          closeBtnText: "Anulează",
          button: {
            type: 3,
            value: "Șterge",
            handler: () => {
              const error = (msg) => {
                this.$toastr.e(msg || "Ștergerea PAAP a eșuat.");
                this.reload();
                this.setLoad();
              };

              this.setSafeLoad(12000);
              PAAP.delete(data.id)
                .then((res) => {
                  if (this.checkHttpStatusCode(res?.meta?.HttpStatusCode)) {
                    this.$toastr.s("PAAP a fost șters cu succes.");
                    this.reload();
                    this.setLoad();
                  } else {
                    error();
                  }
                })
                .catch(error);
            },
          },
        },
        {
          name: "PAAPDelete",
          adaptive: true,
          width: "650",
          height: "400",
        }
      );
    },
    openPaapHistory(versionPaapId, versionNumber, versions, versionsQty) {
      this.$router.push(
        `/${this.$route.params.navigation}/paap/${versionPaapId}/centralizare/history/${versionNumber}`
      );
    },
    closeHistoryModal() {
      this.historyModalOpen = false;
      this.historyModalData = null;
    },
    openHistoryModal(data) {
      this.historyModalData = data;
      this.historyModalOpen = true;
    },
    viewPaapVersions(data) {
      if (!this.isObject(data)) return;
      this.openHistoryModal(data);
    },
    syncUrlForPaap(
      selectedPaapData,
      versionPaapId,
      versionNumber,
      versions,
      versionsQty
    ) {
      versionPaapId = versionPaapId || paapId;

      if (
        this.$route.path == "/paap" &&
        !selectedPaapData &&
        !this.isNumber(versionPaapId)
      ) {
        this.closePanel();
        return;
      }

      const paapId = +this.$route.params.id;

      const version = +this.$route.params.version;
      const centralizationId = +this.$route.params.centralizationId;

      if (Number.isInteger(paapId) && this.isNumber(version)) {
        if (!this.panelData) {
          const error = (msg) => {
            this.$toastr.e(msg || "Încărcarea versiunii a eșuat.");
            this.setLoad();
            this.reload();
          };

          this.setSafeLoad();
          PAAP.getVersions(paapId)
            .then((res) => {
              if (Array.isArray(res?.data?.result)) {
                this.openVersion(
                  paapId,
                  centralizationId,
                  version,
                  res.data.result,
                  res.data.versionsQuantity
                );
              } else {
                error();
              }
            })
            .catch(error);
        }
        return;
      } else if (this.isNumber(versionPaapId)) {
        this.openVersion(versionPaapId, versionNumber, versions, versionsQty);

        return;
      }

      if (Number.isInteger(selectedPaapData?.id)) {
        this.openPanel(() => {
          this.$nextTick(() => {
            return this.safePushRouter(
              `${this.$route.path}/${selectedPaapData.id}/centralizare`
            );
          });

          return {
            action: "edit",
            data: selectedPaapData,
          };
        });

        return;
      }

      if (Number.isInteger(paapId) && !this.panelData) {
        const error = (msg) => {
          this.$toastr.w(msg || "PAAP nu a fost găsit.");
          this.closePanel();
          this.setLoad();
        };

        this.setSafeLoad(12000);
        PAAP.getOne(paapId, centralizationId)
          .then((res) => {
            if (this.isObject(res?.data?.result)) {
              this.openPanel({
                action: "edit",
                data: res.data.result,
              });
            } else {
              error();
            }
          })
          .catch(error);
      }
    },
  },
  computed: {
    showPanel() {
      return !!this.panelData;
    },
    allowTableSync() {
      return !Number.isInteger(+this.$route.params.id);
    },
  },
  watch: {
    $route() {
      this.syncUrlForPaap();
    },
    ReferenceReject: {
      handler(x) {
        x = x?.ReferenceReject;
        this.changeStatus(x.id, x.status, x.callback, true);
      },
      deep: true,
    },
  },
  created() {
    if (!this.PERMISIONS.list) {
      return;
    }

    this.syncUrlForPaap();
    this.updateRoleStatus();
  },
};
</script>
